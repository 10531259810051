<template>
  <div class="box-container">
    <div v-for="item of pressCoverages" :key="item.id" class="box">
      <a :href="item.attributes.url" target="_blank" rel="noreferrer noopener">
        <img
          :src="resolveCmsMediaUrl(item.attributes.logo.data.attributes.url)"
        />
      </a>
    </div>
  </div>
</template>

<script>
import axios from "/utils/axios";
import { resolveCmsMediaUrl } from "/utils/helpers";

const api = {
  pressCoverages: () => {
    return axios.get("press-coverages", {
      params: {
        populate: "logo",
      },
    });
  },
};

export default {
  data() {
    return { pressCoverages: [] };
  },
  beforeMount() {
    this.fetchPressCoverages();
  },
  methods: {
    async fetchPressCoverages() {
      this.pressCoverages = (await api.pressCoverages()).data;
    },
    resolveCmsMediaUrl,
  },
};
</script>

<style lang="scss" scoped>
.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.box {
  height: 7rem;
  width: 220px;
  display: flex;
  justify-content: center;
  a {
    place-self: center center;
    width: auto;
    height: auto;
    margin: 1rem;
  }
}
</style>
